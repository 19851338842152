import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { apiCall } from ".././authentication";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";
import { Link } from "react-router-dom";

const BlogDetailtwo = ({ data }) => {
  const [blogForm, setBlogForm] = useState([]);
  const [newsletterForm, setNewsletterForm] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newerror, setNewError] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [headid, setHeadid] = useState([]);
  const blogRef = useRef(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const checkInputValue = (e) => {
    const input = document.querySelector(".form-control.numericInput");

    // Check if input exists and the value length is already 10
    if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
      e.preventDefault();
      return;
    }
    // Get the entered key
    const key = String.fromCharCode(e.keyCode);

    // Check if the entered key is a number
    const isNumber = /[0-9]/.test(key);

    // Prevent the input if the entered key is not a number
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const slug1 = "custom/v1/contact-form-fields/750";
  const slug2 = "custom/v1/contact-form-fields/752";
  const slug3 = "contact-form-7/v1/contact-forms/750/feedback";
  const slug4 = "contact-form-7/v1/contact-forms/752/feedback";
  const type = "get";
  const type2 = "post";

  useEffect(() => {
    const fetchPost = async (slug) => {
      try {
        const data = await apiCall(slug1, type);
        setBlogForm(data);
      } catch (error) {
        console.error("Error fetching Blog:", error);
      }
    };

    const fetchForm = async (slug) => {
      try {
        const data = await apiCall(slug2, type);
        setNewsletterForm(data);
      } catch (error) {
        console.error("Error fetching Blog:", error);
      }
    };
    //For Get in touch form
    fetchPost();
    fetchForm();
    //For Newslatter

    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }
  }, []);

  // const handleCaptchaChange = (value) => {
  //   // This function will be called when the user successfully verifies the reCAPTCHA.
  //   setIsCaptchaVerified(true);
  // };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    let isEmpty = false;
    formData.append('_wpcf7', '750'); // Correct form ID (this matches the ID of the form)
  formData.append('_wpcf7_version', '6.0'); // Correct Contact Form 7 version
  formData.append('_wpcf7_locale', 'en-US'); // Locale (set appropriately)
  formData.append('_wpcf7_unit_tag', 'wpcf7-f750-o1');
  formData.append('_wpcf7_container_post', '0'); 
    for (const entry of formData.entries()) {
      const [fieldName, value] = entry;
      if (value.trim() === "") {
        if (fieldName === "email") {
          setError((error) => ({
            ...error,
            [fieldName]: `Please enter valid ${fieldName}`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill ${fieldName} field`,
          }));
        }
        isEmpty = true; // Set isEmpty to true if any field is empty
      } else {
        setError((error) => ({
          ...error,
          [fieldName]: "",
        }));
      }
      if (fieldName === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(value);
        if (!isValid) {
          isEmpty = true;
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill valid ${fieldName}`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: "",
          }));
        }
      }
    }

    // if (!isEmpty) {
    const submitForm = async () => {
      try {
        const data = await apiCall(slug3, type2, formData);
        if (data.status === "mail_sent") {
          window.location.replace("/thankyou");
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.error("Error fetching Blog:", error);
      }
    };
    submitForm();
    // }
    // You can perform additional actions or make API requests here
  };

  const handleFormNewSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    let isEmpty = false;
    formData.append('_wpcf7', '752'); // Correct form ID (this matches the ID of the form)
  formData.append('_wpcf7_version', '6.0'); // Correct Contact Form 7 version
  formData.append('_wpcf7_locale', 'en-US'); // Locale (set appropriately)
  formData.append('_wpcf7_unit_tag', 'wpcf7-f752-o1');
    for (const entry of formData.entries()) {
      const [fieldName, value] = entry;
      if (fieldName === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(value);
        if (!isValid) {
          isEmpty = true;

          setNewError((newerror) => ({
            ...newerror,
            [fieldName]: `Please fill valid ${fieldName}`,
          }));
        } else {
          setNewError((newerror) => ({
            ...newerror,
            [fieldName]: "",
          }));
        }
      } else {
        setNewError((newerror) => ({
          ...newerror,
          [fieldName]: "",
        }));
      }
    }

    if (!isEmpty) {
      const submitForm = async () => {
        try {
          const data = await apiCall(slug4, type2, formData);
          if (data.status === "mail_sent") {
            window.location.replace("/thankyou");
          } else {
            alert(data.message);
          }
        } catch (error) {
          console.error("Error fetching Blog:", error);
        }
      };

      submitForm();
    }

    // You can perform additional actions or make API requests here
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    const parseHtml = (html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const headingTags = doc.querySelectorAll("h1, h2, h3, h4, h5, h6");
      const headingTexts = Array.from(headingTags).map(
        (heading, index) => ({
          text: heading.textContent.trim(),
          link: `blog_detail_${index}`
        })
      );
      setHeadings(headingTexts);
      console.log("Headings data:", headingTexts);
    };
    console.log("Headings data:", headings);

    if (data[0]?.content?.rendered) {
      parseHtml(data[0].content.rendered);
    }
  }, [data]);


  useEffect(() => {
    if (!isLoading && blogRef.current) {
        console.log("useEffect is running");

        // Check if the content is rendered inside the blogRef
        const blogContent = blogRef.current.innerHTML;
        console.log("Rendered blog content:", blogContent);

        // Select all h1 to h6 tags within the blogRef element
        const headings = blogRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
        console.log("Found headings:", headings);

        // Add unique IDs to each heading
        headings.forEach((heading, headingIndex) => {
            const newId = `blog_detail_${headingIndex}`;
            heading.id = newId;
            console.log(`Added id ${newId} to heading`, heading);
        });
    }
  }, [isLoading, data]);

  const handleScroll = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
        const offset = element.offsetTop - 100; // Subtract 50 pixels for offset
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });
    }
  };

  console.log("name data", blogRef.current);

  return (
    <>
      <div className="section-spacing pt-0 pb-0">
        <div className="container">
          <div className="row potision-relative align-items-start">
            <div
              className="col-lg-8 mt-5 listing_blog"
            >
              {isLoading ? (
                <>
                  <Conloader />
                  <Conloader />
                </>
              ) : (
                <div ref={blogRef} className="listing_blogs" dangerouslySetInnerHTML={{ __html: data[0]?.content?.rendered }}></div>
              )}
            </div>
            <div className="detail-form col-lg-4 mt-5 col-12">
              {isLoading ? (
                <Imgloader />
              ) : (
                <>
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item blog-form">
                      <h2 class="accordion-header" id="headingContents">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseContents" aria-expanded="false" aria-controls="collapseContents">
                        Table of Contents
                        </button>
                      </h2>
                      <div id="collapseContents" class="accordion-collapse collapse" aria-labelledby="headingContents" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          {/* <div className="table-data-blog" dangerouslySetInnerHTML={{ __html: data[0]?.content?.rendered }}></div> */}
                          <ul className="table_of_contents">
                            {headings.map((heading, index) => (
                              <li key={index}>
                                <Link to={`#${heading.link}`} onClick={(e) => handleScroll(e, heading.link)}>{heading.text}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item blog-form">
                      <h2 class="accordion-header" id="headingGetin">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGetin" aria-expanded="true" aria-controls="collapseGetin">
                        Get in Touch
                        </button>
                      </h2>
                      <div id="collapseGetin" class="accordion-collapse collapse show" aria-labelledby="headingGetin" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <form id="blog-form" onSubmit={handleFormSubmit}>
                            {blogForm &&
                              blogForm.map((response, key) => {
                                return (
                                  <>
                                    <div className="form-group touch-group">
                                      {response.label === "serviceSelect" ? (
                                        <>
                                          {response.options.map((data, index) => {
                                            return (
                                              <>
                                                <div className="services-select">
                                                  <input
                                                    className="form-control touch-control"
                                                    type={response.type}
                                                    name={response.name}
                                                    id={response.name + index}
                                                    value={data}
                                                    for={response.name + index}
                                                    placeholder={data.replaceAll("_", " ")}
                                                  />
                                                  {/* <label for={response.name + index}>
                                                    {data.replaceAll("_", " ")}
                                                  </label> */}
                                                </div>
                                                {Object.keys(error).length > 0 && (
                                                  <div className="error-messages">
                                                    {Object.entries(error).map(
                                                      ([fieldName, errorMessage]) =>
                                                        fieldName === response.name ? (
                                                          <p
                                                            className="text-danger"
                                                            key={fieldName}
                                                          >
                                                            {errorMessage}
                                                          </p>
                                                        ) : null
                                                    )}
                                                  </div>
                                                )}
                                              </>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {/* <label for={response.name}>
                                            {response.label.replaceAll("_", " ")}
                                          </label> */}
                                          <input placeholder={response.label.replaceAll("_", " ")}  for={response.name}
                                            className={`form-control touch-control ${response.type === "tel"
                                              ? "numericInput"
                                              : ""
                                              }`}
                                            type={response.type}
                                            id={response.name}
                                            name={response.name}
                                          />
                                          {Object.keys(error).length > 0 && (
                                            <div className="error-messages">
                                              {Object.entries(error).map(
                                                ([fieldName, errorMessage]) =>
                                                  fieldName === response.name ? (
                                                    <p
                                                      className="text-danger"
                                                      key={fieldName}
                                                    >
                                                      {errorMessage}
                                                    </p>
                                                  ) : null
                                              )}
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            <ReCAPTCHA
                              className="mt-3"
                              sitekey="6Ldr0V8pAAAAAL6BsypDa0TA5tSgQsVKIoRsNz1s"
                              onClick={() => setIsCaptchaVerified(true)}
                            />
                            {Object.entries(error).map(([fieldName, errorMessage]) =>
                              fieldName === "g-recaptcha-response" ? (
                                <p className="text-danger" key={fieldName}>
                                  {errorMessage}
                                </p>
                              ) : null
                            )}
                            <div className="mt-4 blog-submit text-center">
                              <button type="submit" className="btn">
                                <h3>Submit</h3>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item blog-form">
                      <h2 class="accordion-header" id="headingUpdates">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUpdates" aria-expanded="false" aria-controls="collapseUpdates">
                          Get Insightful Articles & Updates delivered to You Monthly
                        </button>
                      </h2>
                      <div id="collapseUpdates" class="accordion-collapse collapse" aria-labelledby="headingUpdates" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <form id="newsletter-form" onSubmit={handleFormNewSubmit}>
                            {newsletterForm &&
                              newsletterForm.map((response, key) => {
                                return (
                                  <>
                                    <div className="form-group artical-group">
                                      <>
                                        {/* <label for={response.name}>
                                          {response.label}*
                                        </label> */}
                                        <input
                                          for={response.name}
                                          className="form-control artical-control"
                                          type={response.type}
                                          id={response.name}
                                          name={response.name}
                                          placeholder={response.label}
                                        />
                                        {Object.entries(newerror).map(
                                          ([fieldName, errorMessage]) =>
                                            fieldName === response.name ? (
                                              <p className="text-danger" key={fieldName}>
                                                {errorMessage}
                                              </p>
                                            ) : null
                                        )}
                                      </>
                                    </div>
                                  </>
                                );
                              })}
                            <div className="mt-4 blog-submit text-center">
                              <button type="submit" className="btn">
                                <h3>Submit</h3>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailtwo;
