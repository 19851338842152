/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, Component } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../Loader";
import Button from "../button/button";
import { apiCall } from "../authentication";

const Header = () => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [headerinnerdata, setHeaderInnerData] = useState([]);
  const [headerdata, setHeaderdata] = useState(null);
  const [headerapidata, setHeaderApiData] = useState([]);
  const [headerSection, setHeaderSection] = useState([]);
  const [quoteData, setQuoteData] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleCaptchaChange = (value) => {
    // This function will be called when the user successfully verifies the reCAPTCHA.
    setIsCaptchaVerified(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const [hoveredLi, setHoveredLi] = useState(null);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsAdd((current) => !current);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  const slug1 = "atlasmenu/customheadermenu";
  const slug2 = "options/all?_fields=header_section";
  const slug3 = "custom/v1/contact-form-fields/29708";
  const slug4 = "contact-form-7/v1/contact-forms/29708/feedback";
  const type = "get";
  const type2 = "post";

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug1, type);
        setHeaderApiData(data);
        const uniqueHeaderMenu = new Set();
        const uniqueSubHeaderMenu = new Set();

        data &&
          data.forEach((_response) => {
            if (_response.menu_item_parent === "0") {
              uniqueHeaderMenu.add(_response);
            } else {
              uniqueSubHeaderMenu.add(_response);
            }
          });

        setHeaderMenu(Array.from(uniqueHeaderMenu));
        setHeaderInnerData(Array.from(uniqueSubHeaderMenu));
        //main heading titles
        setHeaderdata(data);
        setIsLoading(false);
      } catch (error) {
        // Handle API error
        console.error("Error fetching data:", error);
      }
    };

    const headerData = async () => {
      try {
        const data = await apiCall(slug2, type);
        setHeaderSection(data);
        setIsLoading(false);
      } catch (error) {
        // Handle API error
        console.error("Error fetching data:", error);
      }
    };

    const formData = async () => {
      try {
        const data = await apiCall(slug3, type);
        setQuoteData(data);
      } catch (error) {
        // Handle API error
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    headerData();
    formData();
  }, []);

  const handleClose = () => {
    const formElement = document.getElementById("quoteSubmit");
    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };

  const hidepopuprequestquote = () =>{
    const modal = document.getElementById('HeaderFormModal');
    modal.hide();

  }

  const checkInputValue = (e) => {
    const input = document.querySelector(".form-control.numericInput");

    // Check if input exists and the value length is already 10
    if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
      e.preventDefault();
      return;
    }
    // Get the entered key
    const key = String.fromCharCode(e.keyCode);

    // Check if the entered key is a number
    const isNumber = /[0-9]/.test(key);

    // Prevent the input if the entered key is not a number
    if (!isNumber) {
      e.preventDefault();
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    let isEmpty = false;
    formData.append('_wpcf7', '29708'); // Correct form ID (this matches the ID of the form)
  formData.append('_wpcf7_version', '6.0'); // Correct Contact Form 7 version
  formData.append('_wpcf7_locale', 'en-US'); // Locale (set appropriately)
  formData.append('_wpcf7_unit_tag', 'wpcf7-f29708-o1');
  formData.append('_wpcf7_container_post', '0'); 
    for (const entry of formData.entries()) {
      const [fieldName, value] = entry;

      if (value.trim() === "") {
        if (fieldName === "Email-Address") {
          setError((error) => ({
            ...error,
            [fieldName]: `Please enter valid ${fieldName}`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill ${fieldName} field`,
          }));
        }
        isEmpty = true; // Set isEmpty to true if any field is empty
      } else {
        setError((error) => ({
          ...error,
          [fieldName]: "",
        }));
      }
      if (fieldName === "Email-Address") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(value);
        if (!isValid) {
          isEmpty = true;
          setError((error) => ({
            ...error,
            [fieldName]: `Please fill valid ${fieldName}`,
          }));
        } else {
          setError((error) => ({
            ...error,
            [fieldName]: "",
          }));
        }
      }
    }

    if (!isEmpty) {
      if (isCaptchaVerified) {
        const submitForm = async () => {
          try {            
            const data = await apiCall(slug4, type2, formData);
           
            if (data.status === "mail_sent") {
              window.location.replace("/thankyou");
            } else {
              alert(data.message);
            }
          } catch (error) {
            console.error("Error submitting data:", error);
          }
        };
        submitForm();
      } else {
        alert("reCAPTCHA verification failed!");
      }
    }
    // You can perform additional actions or make API requests here
  };

  const hasKeyValuePair = (arr, key, value) => {
    return arr.some((item) => item[key] == value);
  };
  const location = useLocation();
 // console.log("input data", quoteData);

  return (
    <>
      {isLoading ? (
        <div></div>
      ) : (
        <header className={scroll ? "fixed-top" : "fixed-top"}>
          <nav
            className={
              scroll
                ? "navbar navbar-expand-xl navbar-light bg-light justify-content-xl-between container px-md-4 sticky-nav pt-2 pb-2"
                : "navbar navbar-expand-xl navbar-light bg-light justify-content-xl-between container-fluid px-md-4 pt-3 pb-3"
            }
          >
            <Link className="navbar-brand" to="/">
              <img
                src={
                  scroll
                    ? headerSection?.header_section?.sticky_header_logo
                    : headerSection?.header_section?.header_logo
                }
                alt=""
              />
            </Link>
            <button
              className={`navbar-toggler order-3 order-xl-2 ${isMenuOpen ? "collapsed" : ""
                }`}
              onClick={toggleMenu}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                className={`bars ${isMenuOpen ? "active" : ""}`}
                viewBox="0 0 100 100"
              >
                <path
                  className="line top"
                  d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"
                ></path>
                <path
                  className="line middle"
                  d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"
                ></path>
                <path
                  className="line bottom"
                  d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"
                ></path>
              </svg>
            </button>
            <div
              className={`collapse navbar-collapse order-4 order-xl-3 ${isMenuOpen ? "show" : ""
                }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                {headerMenu &&
                  headerMenu.map((response, index) => {
                    const hasIdThree = hasKeyValuePair(
                      headerinnerdata,
                      "menu_item_parent",
                      response.ID
                    );
                    return (
                      <>
                        <li
                          onClick={hasIdThree ? null : closeMenu}
                          className={`nav-item ${hasIdThree ? "dropdown" : " "
                            }`}
                          onMouseEnter={() => setHoveredLi(response.ID)}
                          onMouseLeave={() => setHoveredLi(null)}
                        >
                          <NavLink
                            data-id={`${response.ID}`}
                            className={`nav-link ${response.title == "Services" &&
                              location.pathname.startsWith("/our-services")
                              ? "active"
                              : ""
                              } ${response.title == "Services" &&
                                (location.pathname.startsWith(
                                  "/hire-service"
                                ) ||
                                  location.pathname.startsWith("/hire"))
                                ? "active"
                                : ""
                              } ${response.title == "Build Your Team" &&
                                (location.pathname.startsWith(
                                  "/hire-developers"
                                ) ||
                                  location.pathname.startsWith("/hire"))
                                ? "active"
                                : ""
                              } ${response.title == "Knowledge Center" &&
                                (location.pathname.startsWith("/blog") ||
                                  location.pathname.startsWith("/articles") ||
                                  location.pathname.startsWith("/infographics") ||
                                  location.pathname.startsWith(
                                    "/press-release"
                                  ) ||
                                  location.pathname.startsWith("/case-studies") ||
                                  location.pathname.startsWith("/case-studies"))
                                ? "active"
                                : ""
                              }  ${hasIdThree ? "dropdown-toggle" : " "} ${hoveredLi === response.ID ? "show" : ""
                              } `}
                            to={hasIdThree ? "javascript:;" : response.slug}
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle={hasIdThree ? "dropdown" : ""}
                            aria-expanded={
                              hoveredLi === response.ID ? "true" : "false"
                            }
                            onClick={(e) => {
                              if (window.innerWidth > 1200) {
                                if (hasIdThree) {
                                  navigate(response.slug);
                                }
                              }
                            }}
                          >
                            {response.title}
                          </NavLink>
                          {hasIdThree && (
                            <div
                              className={`dropdown-menu mega-menu-box ${hoveredLi === response.ID ? "show" : ""
                                } `}
                            >
                              <div className="d-flex justify-content-between">
                                <Menus
                                  mainmenudata={headerMenu}
                                  innerContent={headerinnerdata}
                                  mainContentId={response.ID}
                                  closeMenu={closeMenu}
                                />
                              </div>
                            </div>
                          )}
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
            <div className="d-block order-xl-4 order-1">
              <Link
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#HeaderFormModal"
                to="javascript::"
                onClick={handleClose}
              >
                {headerSection?.header_section?.header_button_link?.title}
              </Link>
            </div>
          </nav>
          <div
            class="modal fade"
            id="HeaderFormModal"
            tabindex="-1"
            aria-labelledby="HeaderFormModal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="modal-body" id="careerForm">
                  <div id="quoteForm">
                    <h4>Request for quote</h4>
                    <p className="text-center mb-4">
                      Please fill the form, and our dedicated sales team <br />will be in touch with you very soon.
                    </p>
                    <form id="quoteSubmit" onSubmit={handleFormSubmit}>
                      {quoteData &&
                        quoteData.map((response, index) => {
                          return (
                            <>
                              <div className={`form-group ${
                            response.type === "checkbox" ||
                            response.type === "radio"
                              ? "select_butons"
                              : ""
                          }`}>
                               {response.type === "checkbox" ||
                          response.type === "radio" ? (
                            <>
                              <label>
                                {response.label.replaceAll("-", " ")}
                              </label>
                              {response.options.map((data, index) => {
                                return (
                                  <>
                                    <div className="services-select">
                                      <input
                                        type={response.type}
                                        name={response.name}
                                        id={response.name + index}
                                        value={data}
                                      />
                                      <label for={response.name + index}>
                                        {data}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <label
                                className="contact-label"
                                for={response.name}
                              >
                                {response.label.replaceAll("-", " ")}
                              </label>
                              <div className="error-messages">
                                <input
                                  className={`contact-input ${
                                    response.type === "tel"
                                      ? "numericInput"
                                      : ""
                                  }`}
                                  type={response.type}
                                  id={response.name}
                                  name={response.name}
                                  placeholder={response.options?.[0]}
                                />
                                {Object.keys(error).length > 0 && (
                                  <>
                                    {Object.entries(error).map(
                                      ([fieldName, errorMessage]) =>
                                        fieldName === response.name ? (
                                          <p
                                            className="text-danger"
                                            key={fieldName}
                                          >
                                            {errorMessage}
                                          </p>
                                        ) : null
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                              </div>
                            </>
                          );
                        })}
                      <div className="recaptcha-error-messages error-messages">
                        <p className="fw-600">Click to confirm</p>
                        <ReCAPTCHA
                          sitekey="6Ldr0V8pAAAAAL6BsypDa0TA5tSgQsVKIoRsNz1s"
                          onChange={handleCaptchaChange}
                          className="recaptcha google-recaptcha google-recaptchacenter"
                        />
                        {Object.keys(error).length > 0 && (
                          <>
                            {Object.entries(error).map(
                              ([fieldName, errorMessage]) =>
                                fieldName === "g-recaptcha-response" ? (
                                  <p className="text-danger" key={fieldName}>
                                    {errorMessage}
                                  </p>
                                ) : null
                            )}
                          </>
                        )}
                      </div>
                      <div className="submit_button">
                        <p>Note: This is a sales request form. Interested candidates may kindly <Link to="/careers" onClick={hidepopuprequestquote}>click here to apply.</Link> </p>
                        <button type="submit" className="btn btn-primary">
                          Submit Quote Request
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};
class Menus extends Component {
  constructor() {
    super();
    this.state = {
      hoveredDiv: null, // Track which div is currently being hovered
      isMenuOpen: true,
    };
  }

  handleMouseOver = (divId) => {
    this.setState({ hoveredDiv: divId });
  };

  render() {
    const { innerContent, mainContentId, closeMenu, mainmenudata } = this.props;
    // const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
      <>
        <div className="d-flex nav-sub-menu">
          <div className="nav-list">
            <ul className="nav nav-tabs flex-column" id="myTab" role="tablist">
              {innerContent &&
                innerContent.map((innreResponse, innerKey) => {
                  if (innreResponse.menu_item_parent == mainContentId) {
                    return (
                      <>
                        <li data-bs-toggle="pill" key={innerKey}>
                          <Link
                            data-id={innreResponse.ID}
                            to={innreResponse.ID == 30238 ||
                              innreResponse.ID == 30699 ||
                              innreResponse.ID == 2254 ||
                              innreResponse.ID == 2271 ||
                              innreResponse.ID == 2261 ||
                              innreResponse.ID == 2378 ||
                              innreResponse.ID == 2382 ||
                              innreResponse.ID == 2385
                                ? `#`
                                : `${mainContentId == "2278"
                                  ? "hire-developers"
                                  : mainContentId == "731"
                                  ? "our-services"
                                  : ""
                                }/${innreResponse.slug}`
                        }                        
                            className={`nav-link ${this.state.hoveredDiv == innreResponse.ID
                              ? "actives"
                              : ""
                              }`}
                            onMouseOver={() =>
                              this.handleMouseOver(innreResponse.ID)
                            }
                          >
                            <img
                              src={innreResponse?.icon}
                              alt={innreResponse?.attr_title}
                            />
                            {innreResponse?.title}
                          </Link>
                        </li>
                      </>
                    );
                  }
                })}
            </ul>
          </div>
          <div className="tab-content accordion" id="myTabContent">
            {innerContent &&
              innerContent.map((innerMenu, innerKeyMenu) => {
                if (innerMenu.menu_item_parent == mainContentId) {
                  var subMainId = innerMenu.ID;
                  return (
                    <div
                      inermenukey={innerKeyMenu}
                      className={`tab-pane fade accordion-item ${innerKeyMenu === 0 && this.state.hoveredDiv !== null
                        ? "hoverotheritems"
                        : ""
                        } ${innerKeyMenu === 0 &&
                          this.state.hoveredDiv == innerMenu.ID
                          ? "firsthoveritem"
                          : ""
                        }  ${innerKeyMenu === 0 ? "show active" : ""} ${innerKeyMenu !== 0 &&
                          this.state.hoveredDiv === innerMenu.ID
                          ? "show active"
                          : ""
                        } ${innerKeyMenu !== 0 &&
                          this.state.hoveredDiv !== innerMenu.ID
                          ? "otheritems"
                          : ""
                        }  ${innerKeyMenu === 34 ? "show active" : ""} ${innerKeyMenu === 34 &&
                          this.state.hoveredDiv == innerMenu.ID
                          ? "cmshover"
                          : ""
                        } ${innerKeyMenu === 34 && this.state.hoveredDiv !== null
                          ? "hoverotheritems"
                          : ""
                        } ${innerKeyMenu === 79 ? "show active" : ""} ${innerKeyMenu === 79 &&
                          this.state.hoveredDiv == innerMenu.ID
                          ? "cmshover"
                          : ""
                        } ${innerKeyMenu === 79 && this.state.hoveredDiv !== null
                          ? "hoverotheritems"
                          : ""
                        }`}
                      onMouseOver={() => this.handleMouseOver(innerMenu.ID)}
                      id={`${innerMenu.title}`}
                      role="tabpanel"
                      aria-labelledby="WebDevelopment-tab"
                      tabIndex="0"
                    >
                      <h2
                        className="accordion-header d-lg-none"
                        id={`${innerMenu.title}`}
                      >
                        <button
                          className="accordion-button mobile-accordion"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${innerKeyMenu}`}
                          aria-expanded="true"
                          aria-controls={`#collapse${innerKeyMenu}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <img src={innerMenu?.icon} alt={innerMenu.title} />{" "}
                          {innerMenu.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${innerKeyMenu}`}
                        className="accordion-collapse collapse d-lg-block"
                        aria-labelledby={`${innerMenu.title}`}
                        data-bs-parent="#myTabContent"
                      >
                        <div className="accordion-body">
                          <ul className="nav-pills">
                            {innerContent
                              .filter(
                                (subContent) =>
                                  subContent.menu_item_parent == subMainId
                              )
                              .map((subContent, subIndex) => (
                                <li
                                  onClick={() => {
                                    closeMenu(); // Close the menu from here
                                  }}
                                  key={subIndex}
                                >
                                  <Link
                                    to={
                                      mainContentId == "2323" &&
                                        subMainId == "2385" &&
                                        subContent.title == "Free Guides"
                                        ? `${subContent.url}`
                                        : `${mainContentId == "731"
                                          ? "our-services"
                                          : mainContentId == "2278"
                                            ? "hire"
                                            : ""
                                        }/${subContent.slug}`
                                    }
                                    target={
                                      mainContentId === "2323" &&
                                        subMainId === "2385" &&
                                        subContent.title === "Free Guides"
                                        ? "_blank"
                                        : undefined
                                    }
                                  >
                                    <img
                                      src={subContent?.icon}
                                      alt={subContent.title}
                                    />
                                    {subContent.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null; // Return null for cases where the condition is not met
                }
              })}
          </div>
        </div>
        <div className="web-bevelopment">
          {innerContent &&
            innerContent.map((innreResponse, innerKey) => {
              if (innreResponse.menu_item_parent == mainContentId) {
                return (
                  <>
                    <div
                      className={`tab-pane fade accordion-item ${innerKey === 0 && this.state.hoveredDiv !== null
                        ? "hoverotheritems"
                        : ""
                        } ${innerKey === 0 &&
                          this.state.hoveredDiv == innreResponse.ID
                          ? "firsthoveritem"
                          : ""
                        }  ${innerKey === 0 ? "show d-block" : ""} ${innerKey !== 0 &&
                          this.state.hoveredDiv === innreResponse.ID
                          ? "show d-block"
                          : ""
                        } ${innerKey !== 0 &&
                          innerKey !== 34 &&
                          innerKey !== 79 &&
                          this.state.hoveredDiv !== innreResponse.ID
                          ? "d-lg-none"
                          : ""
                        } ${innerKey === 34 ? "show d-block" : ""} ${innerKey === 79 ? "show d-block" : ""
                        } ${innerKey === 34 &&
                          this.state.hoveredDiv == innreResponse.ID
                          ? "cmshover"
                          : ""
                        } ${innerKey === 34 && this.state.hoveredDiv !== null
                          ? "hoverotheritems"
                          : ""
                        } ${innerKey === 79 &&
                          this.state.hoveredDiv == innreResponse.ID
                          ? "cmshover"
                          : ""
                        } ${innerKey === 79 && this.state.hoveredDiv !== null
                          ? "hoverotheritems"
                          : ""
                        }`}
                      onMouseOver={() => this.handleMouseOver(innreResponse.ID)}
                    >
                      <h5>{innreResponse.attr_title}</h5>
                      <p>{innreResponse.description}</p>
                      {mainmenudata &&
                        mainmenudata.length > 0 &&
                        mainmenudata.map((headercontact, index) => (
                          <React.Fragment key={index}>
                            {headercontact.title === "Contact" &&
                              index ===
                              mainmenudata.findIndex(
                                (item) => item.title === "Contact"
                              ) ? (
                              <Button
                                link={`${headercontact.slug}`}
                                class="blue-bg"
                                title={`${headercontact.title}`}
                                name={`${headercontact.title}`}
                              />
                            ) : null}
                          </React.Fragment>
                        ))}
                    </div>
                  </>
                );
              }
            })}
        </div>
      </>
    );
  }
}

export default Header;
